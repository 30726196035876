
<app-hero [image]="'img-somtum'"></app-hero>

<div class="section" role="main">
    <h1>Tumnan Thai</h1>
    <div class="subsection">

        <div class="subsection-col info-aboutus">
            <span>Tumnan Thai is the legend of authentic Thai cuisine, inspired from a rich Thai heritage. All of our family recipes have their roots right from the heart of Bangkok itself.</span><br/>
            <span>For now over 20 years we have served the most Tantalizing Green and Red Curry, Aromatic Chicken Satay and our famous Phad Thai that will transport your taste buds straight to Thailand</span><br/>
            <span>Thai food is an exotic journey of the senses, created from a perfect blend of healthy herbs and spices to bring you into our world of flavour and harmony.</span> 
        </div>
        <div class="subsection-col info-opening">
            <h3>Opening Times</h3>
            <span>Sun - Thu: 6:00 - 11:00pm</span>
            <span>Fri - Sat: 6:00 - 11:30pm</span>
            <button routerLink="/menu">See the Menu</button>
            <button routerLink="/reservations">Book a Table</button>
        </div>
        <div class="subsection-col info-contact">
            <span class="info-contact_phone">
                <i class="material-icons">phone</i> <a href="tel:02072231046">0207 223 1046</a>
            </span>
            <span class="info-contact_mail">
                <i class="material-icons">email</i> <a href="mailto:hibkk@aol.com">hibkk@aol.com</a>
            </span>
            <span class="info-contact_location">
                <i class="material-icons">room</i> <a href="https://www.google.co.uk/maps/place/Tumnan+Thai/@51.4648639,-0.1610976,17z/data=!3m1!4b1!4m5!3m4!1s0x487605a3796b5127:0xaa5da799e47dc576!8m2!3d51.464854!4d-0.1589073" target="_blank">163 Lavender Hill, SW11 5QH - London</a>
            </span>
        </div>
    </div>
</div>

<app-banner [image]="'img-food'"></app-banner>

<div class="section">
    
    <div class="subsection">
        <div class="subsection-col info-reservations">
            <h1>Table Reservations</h1>
            <div class="info-reservations-text">
                <span>For takeaways and deliveries, </span>
                <span>Please call: <a [href]="'tel:' + 02072231046">0207 223 1046</a></span>
                <span>or book on:</span>
            </div>
            <iframe src="https://www.quandoo.co.uk/place/5555/widget?aid=2" width="100%" height="500px" seamless="seamless" scrolling="no" frameborder="0"></iframe>
    
        </div>
        <div class="subsection-col info-takeaway">
            <h1>Takeaways & Delivery</h1>
                <div class="info-takeaway-text">
                    <span>For takeaways and deliveries, </span>
                    <span>Please call: <a [href]="'tel:' + 02072231046">0207 223 1046</a></span>
                    <span>or order on:</span>
                </div>
                <a href="https://www.just-eat.co.uk/restaurants-tumnanthai-sw11/menu">
                    <img alt="just-eat" src="./../../assets/images/just-eat.jpg" width="80">
                </a>
                <a href="https://deliveroo.co.uk/menu/london/lavender-hill/tumnan-thai-restaurant">
                    <img alt="deliveroo" src="./../../assets/images/deliveroo.png" width="80">
                </a>
        </div>
    </div>
</div>

<app-banner [image]="'img-upstairs'"></app-banner>

<div class="section">
    <h1 id="booking">Find us Here</h1>
    <div class="subsection">
        <div class="subsection-col info-contact info-map-contact">
            <span class="info-contact_phone">
                <i class="material-icons">phone</i> <a href="tel:02072231046">0207 223 1046</a>
            </span>
            <span class="info-contact_mail">
                <i class="material-icons">email</i> <a href="mailto:hibkk@aol.com">hibkk@aol.com</a>
            </span>
            <span class="info-contact_location">
                <i class="material-icons">room</i> <a href="https://www.google.com/maps/place/Tumnan+Thai/@51.4653886,-0.1595606,18z/data=!4m8!1m2!3m1!2sTumnan+Thai!3m4!1s0x487605a3796b5127:0xaa5da799e47dc576!8m2!3d51.464854!4d-0.1589073" target="_blank">163 Lavender Hill, SW11 5QH - London</a>
            </span>
        </div>
        <div class="subsection-col info-map">
            <iframe width="100%" height="100%" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4381.501006842967!2d-0.15942780514678226!3d51.464530966897996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605a3796b5127%3A0xaa5da799e47dc576!2sTumnan%20Thai!5e0!3m2!1sen!2suk!4v1622736173709!5m2!1sen!2suk" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
    </div>
</div>
<app-footer></app-footer>
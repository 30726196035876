
<app-hero [image]="'img-table'"></app-hero>

<div class="section" role="main">

    <div class="reservations subsection">
        <div class="subsection-col reservations-info">
            <h1>Bookings & Reservations</h1>
            <div class="reservations-info-text">
                <span>For takeaways and deliveries, </span>
                <span>Please call: <a [href]="'tel:' + 02072231046">0207 223 1046</a></span>
                <span>Email: <a href="mailto:hibkk@aol.com">hibkk@aol.com</a></span>
                <span>or book on:</span>
            </div>
            <iframe src="https://www.quandoo.co.uk/place/5555/widget?aid=2" width="320" height="540" seamless="seamless" scrolling="no" frameborder="0"></iframe>
    
        </div>
        <div class="subsection-col reservations-map">
            <iframe width="100%" height="100%" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4381.501006842967!2d-0.15942780514678226!3d51.464530966897996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605a3796b5127%3A0xaa5da799e47dc576!2sTumnan%20Thai!5e0!3m2!1sen!2suk!4v1622736173709!5m2!1sen!2suk" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
    </div>
</div>


<app-footer></app-footer>